import { mapState } from 'vuex'
export const roles = {
    computed: {
        ...mapState({
            userRoles: state => state.userInfo.info.roles,
        }),
        isRoleAdmin () {
            // Check if role is 'admin'
            return this.userRoles.some(role => role === 'admin')
        },
    },
}
